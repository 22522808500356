<template>
<div class="mainform">
  <div class="form">
    <el-form ref="form" class="content bascform" label-width="110px">
        <div class="col col8" v-if="judgeDate(bidEndDate)">
            <el-form-item label="应标截止日期">
                <el-date-picker v-model="bidEndDate" type="datetime" placeholder="应标截止日期"></el-date-picker>
            </el-form-item>
        </div>
        <div class="col col8" v-if="judgeDate(bidStartDate)">
            <el-form-item label="开标日期">
                <el-date-picker v-model="bidStartDate" type="datetime" placeholder="开标日期"></el-date-picker>
            </el-form-item>
        </div>
        <div style="padding-left:10px;margin-bottom:5px;" v-if="judgeDate(bidEvaluationEndTime)">
            <el-form-item label="评标日期">
                <el-date-picker class="starttime" v-model="bidEvaluationStartTime" type="datetime" placeholder="选择日期"></el-date-picker>
                -
                <el-date-picker v-model="bidEvaluationEndTime" type="datetime" placeholder="选择日期"></el-date-picker>
            </el-form-item>
        </div>
    </el-form>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import Component from '@/assets/js/components.js'
export default {
  name: 'ChangeBid',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      bidEndDate: this.formData.bidEndDate,
      bidStartDate: this.formData.bidStartDate,
      bidEvaluationStartTime: this.formData.bidEvaluationStartTime,
      bidEvaluationEndTime: this.formData.bidEvaluationEndTime,
      formdata: { bidEndDate: this.formData.bidEndDate, bidStartDate: this.formData.bidStartDate, bidEvaluationStartTime: this.formData.bidEvaluationStartTime, bidEvaluationEndTime: this.formData.bidEvaluationEndTime }
    }
  },
  watch: {
    bidEndDate: function (val) {
      this.dateFormate(this.formdata, 'bidEndDate', val)
    },
    bidStartDate: function (val) {
      this.dateFormate(this.formdata, 'bidStartDate', val)
    },
    bidEvaluationStartTime: function (val) {
      this.dateFormate(this.formdata, 'bidEvaluationStartTime', val)
    },
    bidEvaluationEndTime: function (val) {
      this.dateFormate(this.formdata, 'bidEvaluationEndTime', val)
    }
  },
  methods: {
    dateFormate: function (obj, field, data) {
      obj[field] = dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    judgeDate (date) {
      return new Date(date) > new Date(sessionStorage.getItem('serversDate'))
    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
</style>
